/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import ContentRightSidebarLayout from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  TemplateBreadCrumbs,
  Typography,
  MdxWrapper,
  Accordion,
  PressOfficeWidget,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
} from '../../components'
import { head, path, pathOr } from 'ramda'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { format } from 'date-fns'
// import styles from './CoursePageTemplate.module.css'

const shortcodes = {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
}

export const CoursePageTemplate = ({ data }) => {
  const { page, categories } = data
  const { frontmatter } = page
  const { title } = frontmatter

  const firstCategory = head(frontmatter.categories)
  const category = categories.edges.find(
    c => c.node.frontmatter.title === firstCategory,
  )

  const breadcrumbs = [
    {
      label: 'Courses',
      url: '/educating',
    },
    {
      label: title,
    },
  ]

  const faqs =
    page.frontmatter.faqs === null
      ? []
      : [
          {
            title: 'Course Information',
            iconUrl: '',
            content: (
              <MDXProvider components={shortcodes}>
                <MdxWrapper>
                  <MDXRenderer>
                    {pathOr('', ['frontmatter', 'faqs', 'information'], page)}
                  </MDXRenderer>
                </MdxWrapper>
              </MDXProvider>
            ),
          },
          {
            title: 'Tuition Fees',
            iconUrl: '',
            content: (
              <MDXProvider components={shortcodes}>
                <MdxWrapper>
                  <MDXRenderer>
                    {pathOr('', ['frontmatter', 'faqs', 'tuition'], page)}
                  </MDXRenderer>
                </MdxWrapper>
              </MDXProvider>
            ),
          },
          {
            title: 'Course Dates',
            iconUrl: '',
            content: (
              <MDXProvider components={shortcodes}>
                <MdxWrapper>
                  <MDXRenderer>
                    {pathOr('', ['frontmatter', 'faqs', 'dates'], page)}
                  </MDXRenderer>
                </MdxWrapper>
              </MDXProvider>
            ),
          },
        ]

  const applicationUrl = path(['applyUrl'], frontmatter)
  const startDate = path(['startDate'], frontmatter)
  const applicationDeadline = path(['applicationDeadline'], frontmatter)

  return (
    <MDXProvider components={shortcodes}>
      <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />
      <Divider thickness={4} />

      <MdxWrapper>
        <Typography tag="h1" type="boxHeading">
          {path(['node', 'frontmatter', 'title'], category)}
        </Typography>
        <Typography type="lead">
          {path(['node', 'frontmatter', 'summary'], category)}
        </Typography>
        <Typography tag="h2" type="h3">
          {path(['frontmatter', 'title'], page)}
        </Typography>
        <Typography tag="p">
          {startDate && (
            <>
              <strong className="text-teal-light uppercase">
                Application Deadline:{' '}
              </strong>
              {format(new Date(applicationDeadline), 'dd MMMM yyyy')}
              <br />
            </>
          )}
          {applicationDeadline && (
            <>
              <strong className="text-teal-light uppercase">
                Course starts:{' '}
              </strong>
              {format(new Date(startDate), 'dd MMMM yyyy')}
            </>
          )}
        </Typography>
        <MDXRenderer>{path(['frontmatter', 'editor'], page)}</MDXRenderer>
      </MdxWrapper>

      {faqs.length > 0 && (
        <>
          <Typography tag="h3" type="h4" className="my-4">
            Course FAQs
          </Typography>
          <Accordion items={faqs} className="mb-8" />
        </>
      )}

      {applicationUrl && (
        <a
          href={applicationUrl}
          target="_blank"
          rel="noreferrer "
          className="button"
        >
          Apply now
        </a>
      )}
    </MDXProvider>
  )
}

CoursePageTemplate.propTypes = {}

const CoursePage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <ContentRightSidebarLayout header={header}>
      <div>
        <CoursePageTemplate data={data} />
      </div>
      <div>
        <PressOfficeWidget />
      </div>
    </ContentRightSidebarLayout>
  )
}

CoursePage.propTypes = {
  page: PropTypes.shape({
    frontmatter: PropTypes.shape({
      header: headerProps,
      title: PropTypes.string,
    }),
  }),
  categories: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          fields: PropTypes.any,
          frontmatter: PropTypes.any,
        }),
      }),
    ),
  }),
  // tags: PropTypes.shape({
  //   edges: PropTypes.arrayOf(PropTypes.shape({
  //     node: PropTypes.shape({
  //       frontmatter: PropTypes.shape({
  //         title: PropTypes.string
  //       })
  //     })
  //   }))
  // })
}

export default CoursePage

export const pageQuery = graphql`
  query CoursePageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        title
        applicationDeadline
        startDate
        featuredImage {
          childCloudinaryMediaImage {
            fixed(width: 400) {
              aspectRatio
              height
              src
              srcSet
              width
            }
          }
        }
        editor
        applyUrl
        categories
        faqs {
          information
          tuition
          dates
        }
      }
    }
    categories: allMdx(
      filter: { fields: { contentType: { eq: "courseCategories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            summary
          }
        }
      }
    }
  }
`
